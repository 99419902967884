const awscognito = {
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_qbFsli3B8",
    userPoolWebClientId: "46scrunedvfuhu8s5sn4dmehhf",
    authenticationFlowType: "CUSTOM_AUTH",
  },
};

export default awscognito;
